import * as React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

import Layout from "../components/layout"
import Nav from "../components/nav"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

const main = css`
    width: 100%;
    padding-top: 58px;
`

const title = css`
    color: #4d4c9b;
`

const container = css`
    padding: 2rem;
    @media (min-width: 500px) {
        padding: 4rem;
    }
`

const MentionsPage = () => {
    return (
        <Layout>
            <SEO title={'Mentions Légales'} />
            <Nav />
            <main css={main.styles}>
                <div css={container.styles}>
                    <h1 css={title.styles}>Mentions Légales</h1>
                    <p>SARL JAAD GROUP</p>
                    <p>RCS de PARIS - n° SIREN 823 092 101</p>
                    <p>Capital social de 1000,00€</p>
                    <p>10 rue de Penthièvre 75008 PARIS</p>
                    <p>Email : contact@jaad-group.com</p>
                    <p><strong>Hébergeur:</strong></p>
                    <p>OVH</p>
                    <p>RCS Lille Métropole 424 761 419 00045</p>
                    <p>N° TVA : FR 22 424 761 419</p>
                    <p>Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>
                </div>
                <div className={"section"} id="footer">
                    <Footer />
                </div>
            </main>
        </Layout>
    )
}

export default MentionsPage
